import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { func } from "prop-types";
// import * as XLSX from "xlsx";
// import { v4 as uuidv4 } from "uuid";
// import Get from "../../Get";

function ListadoInventarios() {
  const { user } = useContext(AuthContext);
  const URL_INVENTARIOS = `${process.env.REACT_APP_URL_INVENTARIOS}`;
  const URL_MARCAS = process.env.REACT_APP_URL_MARCAS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_COLECCIONES = process.env.REACT_APP_URL_COLECCIONES;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  const AREAS_TMK = process.env.REACT_APP_AREAS_TMK;

  const [marcas, setMarcas] = useState([]);
  const [selectedMarca, setSelectedMarca] = useState("");
  const [colecciones, setColecciones] = useState([]);
  const [selectedColeccion, setSelectedColeccion] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  
  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [activo, setActivo] = useState("");

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const [idEditArticulo, setIdEditArticulo] = useState("");
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;
  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);

  const [desgloceArticulo, setDesgloceArticulo] = useState([]);
  const [desgloceCodigo, setDesgloceCodigo] = useState("");
  const [desgloceNombre, setDesgloceNombre] = useState("");
  const [desgloceMarca, setDesgloceMarca] = useState("");
  const [desgloceColeccion, setDesgloceColeccion] = useState("");
  const [modalDesgloce, setModalDesgloce] = useState(false);
  const toggleDesgloce = () => setModalDesgloce(!modalDesgloce);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  // const [totalCaptura, setTotalCaptura] = useState(1);

  const ITEMS_PER_PAGE = 50;

  let hoy = new Date();
  hoy.setDate(hoy.getDate() - 3650);
  const year = hoy.getFullYear();
  const years = Array.from(new Array(40), (val, index) => index + year);

  // const [inputFields, setInputFields] = useState([
  //   {
  //     id: uuidv4(),
  //         areas:"",
  //         articulos:"",
  //         colores:"",
  //         tallas:"",
  //         ubicacion:"",
  //         cantidad:0,
  //         segundas:0,
  //         is_active:"Si"
  //   },
  // ]);

  useMemo(() => {
    axios
      .get(`${URL_INVENTARIOS}Acumulado`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInventarios = response.data;
        // Array para pagination
        let arrayTabla = allInventarios
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            if(a.cantidad >0){
            return {
              _id: a._id,
              activo: a.activo,
              cantidad: a.cantidad,
              segundas: a.segundas,
              codigo: a.codigo,
              nombre: a.nombre,
              idArticulo: a.idArticulo,
              // marca: a.marca,
              // idMarca: a.idMarca,
              coleccion: a.coleccion,
              idColeccion: a.idColeccion,
              area: a.area,
              idArea: a.idArea
            }
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
      
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_MARCAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allMarcas = response.data;
        setMarcas(allMarcas);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLECCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColecciones = response.data;
        setColecciones(allColecciones);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAreas = response.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);



  function PDFTabla() {
    let total_general = 0;
    let total_segundas = 0;
    let agrupado = comments.reduce(function (groups, item) {
      const val = item["idArticulo"] + item["idArea"];
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        cantidad: 0,
        segundas: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].segundas += item.segundas;
      groups[val]._id = item._id;
      groups[val].activo = item.activo;
      groups[val].nombre = item.nombre;
      groups[val].codigo = item.codigo;
      groups[val].idArticulo = item.idArticulo;
      // groups[val].marca = item.marca;
      // groups[val].idMarca = item.idMarca;
      groups[val].coleccion = item.coleccion;
      groups[val].idColeccion = item.idColeccion;
      groups[val].area = item.area;
      groups[val].idArea = item.idArea;
      return groups;
    },[])

    let computedComments = Object.values(agrupado);

    const data = computedComments
    .filter(
      (comment) =>
        comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
        comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
        // comment.marca.toLowerCase().includes(search.toLowerCase()) ||
        comment.coleccion.toLowerCase().includes(search.toLowerCase()) ||
        comment.area.toLowerCase().includes(search.toLowerCase()) ||
        comment.cantidad.toString().includes(search) ||
        comment.segundas.toString().includes(search)
    )
      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
      .map((a) => {
        if (
          (selectedArticulo == 0 || selectedArticulo == a.idArticulo) &&
          // (selectedMarca == 0 || selectedMarca == a.idMarca) &&
          (selectedColeccion == 0 || selectedColeccion == a.idColeccion) &&
          (selectedArea == 0 || selectedArea == a.idArea) &&
          (activo == 0 || activo == a.is_active)
        ) {
          total_general = parseFloat(total_general) + parseFloat(a.cantidad)
          total_segundas = parseFloat(total_segundas) + parseFloat(a.segundas)
          return [
            a.codigo,
            a.nombre,
            // a.marca,
            a.coleccion,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US").format(a.segundas),
            a.area
          ];
        }
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Inventarios`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          // "Marca",
          "Coleccion",
          "Cantidad",
          "Segundas",
          "Almacen",

        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          // "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(total_general),
          new Intl.NumberFormat("en-US").format(total_segundas),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Inventarios.pdf`);
  }

  function excel() {
    let total_general = 0;
    let total_segundas = 0;
    let agrupado = comments.reduce(function (groups, item) {
      const val = item["idArticulo"] + item["idArea"];
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        cantidad: 0,
        segundas: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].segundas += item.segundas;
      groups[val]._id = item._id;
      groups[val].activo = item.activo;
      groups[val].nombre = item.nombre;
      groups[val].codigo = item.codigo;
      groups[val].idArticulo = item.idArticulo;
      // groups[val].marca = item.marca;
      // groups[val].idMarca = item.idMarca;
      groups[val].coleccion = item.coleccion;
      groups[val].idColeccion = item.idColeccion;
      groups[val].area = item.area;
      groups[val].idArea = item.idArea;
      return groups;
    },[])

    let computedComments = Object.values(agrupado);

    const data = computedComments
    .filter(
      (comment) =>
        comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
        comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
        // comment.marca.toLowerCase().includes(search.toLowerCase()) ||
        comment.coleccion.toLowerCase().includes(search.toLowerCase()) ||
        comment.area.toLowerCase().includes(search.toLowerCase()) ||
        comment.cantidad.toString().includes(search) ||
        comment.segundas.toString().includes(search)
    )
      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
      .map((a) => {
        if (
          (selectedArticulo == 0 || selectedArticulo == a.idArticulo) &&
          // (selectedMarca == 0 || selectedMarca == a.idMarca) &&
          (selectedColeccion == 0 || selectedColeccion == a.idColeccion) &&
          (selectedArea == 0 || selectedArea == a.idArea) &&
          (activo == 0 || activo == a.is_active)
        ) {
          total_general = parseFloat(total_general) + parseFloat(a.cantidad)
          total_segundas = parseFloat(total_segundas) + parseFloat(a.segundas)
        return {
          Codigo: a.codigo,
          Nombre: a.nombre,
          // Marca: a.marca,
          Coleccion: a.coleccion,
          Cantidad: a.cantidad,
          Segundas: a.segundas,
          Almacen: a.area
        };
      }
    });
    const dataExportExcel = Object.values(data);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoInventarios";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoInventarios",
        sheetFilter: [
          "Codigo",
          "Nombre",
          // "Marca",
          "Coleccion",
          "Cantidad",
          "Segundas",
          "Almacen",
        ],
        sheetHeader: [
          "Codigo",
          "Nombre",
          // "Marca",
          "Coleccion",
          "Cantidad",
          "Segundas",
          "Almacen",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let total_general = 0;
    let total_segundas = 0;
    let agrupado = comments.reduce(function (groups, item) {
      const val = item["idArticulo"] + item["idArea"];
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        cantidad: 0,
        segundas: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].segundas += item.segundas;
      groups[val]._id = item._id;
      groups[val].activo = item.activo;
      groups[val].nombre = item.nombre;
      groups[val].codigo = item.codigo;
      groups[val].idArticulo = item.idArticulo;
      // groups[val].marca = item.marca;
      // groups[val].idMarca = item.idMarca;
      groups[val].coleccion = item.coleccion;
      groups[val].idColeccion = item.idColeccion;
      groups[val].area = item.area;
      groups[val].idArea = item.idArea;
      return groups;
    },[])

    let computedComments = Object.values(agrupado);

    const data = computedComments
    .filter(
      (comment) =>
        comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
        comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
        // comment.marca.toLowerCase().includes(search.toLowerCase()) ||
        comment.coleccion.toLowerCase().includes(search.toLowerCase()) ||
        comment.area.toLowerCase().includes(search.toLowerCase()) ||
        comment.cantidad.toString().includes(search) ||
        comment.segundas.toString().includes(search)
    )
      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
      .map((a) => {
        if (
          (selectedArticulo == 0 || selectedArticulo == a.idArticulo) &&
          // (selectedMarca == 0 || selectedMarca == a.idMarca) &&
          (selectedColeccion == 0 || selectedColeccion == a.idColeccion) &&
          (selectedArea == 0 || selectedArea == a.idArea) &&
          (activo == 0 || activo == a.is_active)
        ) {
          total_general = parseFloat(total_general) + parseFloat(a.cantidad)
          total_general = parseFloat(total_general) + parseFloat(a.segundas)
          return [
            a.codigo,
            a.nombre,
            // a.marca,
            a.coleccion,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US").format(a.segundas),
            a.area
          ];
        }
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Inventarios`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          // "Marca",
          "Coleccion",
          "Cantidad",
          "Segundas",
          "Almacen",

        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          // "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(total_general),
          new Intl.NumberFormat("en-US").format(total_segundas),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Inventarios",
          email: mailTo,
          fileName: "ListadoInventarios.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Inventarios.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

 

  const headers = [
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    // { name: "Marca", field: "marca", sortable: true },
    { name: "Coleccion", field: "coleccion", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
    { name: "Segundas", field: "segundas", sortable: true },
    { name: "Almacen", field: "area", sortable: true },
    { name: "Detalles", field: "foto", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          // comment.marca.toLowerCase().includes(search.toLowerCase()) ||
          comment.coleccion.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.segundas.toString().includes(search)
      );
    }

    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }
    // if (selectedMarca) {
    //   computedComments = computedComments.filter((e) =>
    //     e.idMarca.includes(selectedMarca)
    //   );
    // }
    if (selectedArticulo) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedArticulo)
      );
    }
    if (selectedColeccion) {
      computedComments = computedComments.filter((e) =>
        e.idColeccion.includes(selectedColeccion)
      );
    }
    if (selectedArea) {
      computedComments = computedComments.filter((e) =>
        e.idArea.includes(selectedArea)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" &&
      sorting.field != "segundas"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "segundas"
        )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "segundas"
        )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedArticulo,
    activo,
    // selectedMarca, 
    selectedColeccion,
    selectedArea,
  ]);


  function limpiarSearch(){
    setSearch("")
    setSelectedArticulo("")
    // setSelectedMarca("")
    setSelectedColeccion("")
    setSelectedArea("")
  }

  
  function jalaFoto(
    idEdit
  ) {
    setPhoto("")
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idEdit}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        if (data.data.version) {
          setPhoto(data.data.version);
        }
      })
      .catch((err) => console.log(err));


    setIdEditArticulo(idEdit);
    toggleFoto();
  }

  function jalaInfo(idArticulo, codigo, nombre, coleccion, idArea){

  setDesgloceCodigo(codigo)
  setDesgloceNombre(nombre)
  // setDesgloceMarca(marca)
  setDesgloceColeccion(coleccion)

  axios
    .get(`${URL_INVENTARIOS}ArticuloArea/${idArticulo}/${idArea}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allDesgloceArticulo = response.data;
      let array = allDesgloceArticulo.map((a)=>{
        return{
          idColor: a.colores[0]._id,
          color: a.colores[0].name,
          idTalla: a.tallas[0]._id,
          talla: a.tallas[0].name,
          cantidad: a.cantidad,
          segundas: a.segundas,
          ubicacion: a.ubicacion
        }
      })
        let dataInv = Object.values(array);

        let agrupado = dataInv
        .sort((a, b) => (a.ubicacion > b.ubicacion ? 1 : -1))
        .reduce(function (groups, item) {
          const val = item["idColor"] + item["idTalla"];
          groups[val] = groups[val] || {
            idColor: item.idColor,
            idTalla: item.idTalla,
            ubicacion: "",
            cantidad:0,
            segundas:0
          };
          groups[val].color = item.color;
          groups[val].talla = item.talla;
          groups[val].idColor = item.idColor;
          groups[val].idTalla = item.idTalla;
          groups[val].cantidad += item.cantidad;
          groups[val].segundas += item.segundas;
          groups[val].ubicacion +=
            "[" + item.ubicacion + " / " + item.cantidad + "] - ";
          return groups;
        }, []);

        let dataAgrupado = Object.values(agrupado);

      setDesgloceArticulo(dataAgrupado);
    })
    .catch((err) => {
      console.log(err);
    });

    toggleDesgloce()
  }

  // function importarArchivo(event) {
  //   const target = event.target;
  //   const value = target.type === "checkbox" ? target.checked : target.value;
  //   const name = target.name;
  //   let hojas = [];

  //   if (name === "file") {
  //     let reader = new FileReader();
  //     reader.readAsArrayBuffer(target.files[0]);
  //     reader.onloadend = (e) => {
  //       var data = new Uint8Array(e.target.result);
  //       var workbook = XLSX.read(data, { type: "array" });

  //       workbook.SheetNames.forEach(function (sheetName) {
  //         // Here is your object
  //         var XL_row_object = XLSX.utils.sheet_to_json(
  //           workbook.Sheets[sheetName]
  //         );
  //         hojas.push({
  //           data: XL_row_object,
  //           sheetName,
  //         });
  //       });

  //       let A1 = hojas[0].data;

  //       let TC = totalCaptura;
  //       A1.map((a) => {
  //         TC = TC + 1;
  //         setTotalCaptura(TC);
  //         inputFields.push({
  //           id: uuidv4(),
  //           areas: a.areas,
  //           articulos: a.articulos,
  //           colores: a.colores,
  //           tallas: a.tallas,
  //           ubicacion: a.ubicacion,
  //           cantidad: a.cantidad,
  //           segundas: 0,
  //           is_active: "Si",
  //         });
  //       });

  //       inputFields.map(async(a)=>{
  //         await axios
  //         .post(
  //           `${URL_INVENTARIOS}`,
  //           {
  //             areas: a.areas,
  //             articulos: a.articulos,
  //             colores: a.colores,
  //             tallas: a.tallas,
  //             ubicacion: a.ubicacion,
  //             cantidad: a.cantidad,
  //             segundas: 0,
  //             is_active: "Si",
  //           },
  //           {
  //             headers: {
  //               Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //             },
  //           }
  //         )
  //         .then(() => {
  //           Swal.fire("Good job!", "Creado con exito", "success");
  //         })
  //         .catch((error) => {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Oops...",
  //             text: "Something went wrong!",
  //             footer: `${error.response.data}`,
  //           });
  //           console.log(error);
  //         })
  //       })

  //     };
  //   }
  // }
  // console.log("hola", inputFields)

  let total = 0;
  let totalSegundas = 0;
  return (
    <>
      <Header />
      <br />
      <br />
      {user.inventarios ? (
        <div className="card col-12">
          {/* <Row>
                      <Col sm={6}>
                        <Input
                          required
                          type="file"
                          name="file"
                          id="file"
                          onChange={(event) => importarArchivo(event)}
                        />
                      </Col>
                    </Row> */}
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuInventarios"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
              
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Inventarios</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
         
          <h3 align="center">Inventario</h3>
          {/* <Get/> */}
          <div className="row">
            <div className="col-md-10">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div> <div className="col-md-1 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
             <div className="col-md-1 flex-row-reverse">
            <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={limpiarSearch}
                >
                  Limpiar
                </Button>
                </div>
           
          </div>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArticulo}
                  onChange={(e) => {
                    setSelectedArticulo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulos
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.codigo}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArticulo}
                  onChange={(e) => {
                    setSelectedArticulo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulos
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre}</option>;
                    })}
                </Input>
              </td>
              
              {/* <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedMarca}
                  onChange={(e) => {
                    setSelectedMarca(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {marcas
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td> */}

             
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColeccion}
                  onChange={(e) => {
                    setSelectedColeccion(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colecciones
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>

              
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArea}
                  onChange={(e) => {
                    setSelectedArea(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if (a._id != AREAS_GENERAL) {
                        if (user.areas == AREAS_GENERAL) {
                          return <option value={a._id}>{a.name}</option>;
                        } else if (user.areas == AREAS_TMK) {
                          if (
                            a._id == AREAS_TMK ||
                            a._id == AREAS_CEDIS
                          ) {
                            return <option value={a._id}>{a.name}</option>;
                          }
                        } else if (user.areas == a._id) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      }
                    })}
                </Input>
              </td>
              <td></td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                total = total + parseFloat(a.cantidad);
                totalSegundas = totalSegundas + parseFloat(a.segundas);
                {
                  return (
                    <tr>
                      <td>{a.codigo}</td>
                      <td>{a.nombre}</td>
                      {/* <td>{a.marca}</td> */}
                      <td>{a.coleccion}</td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.cantidad)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.segundas)}
                      </td>
                      <td>{a.area}</td>
                      <td>
                      <Button
                              color="success"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.idArticulo,
                                  a.codigo,
                                  a.nombre,
                                  // a.marca,
                                  a.coleccion,
                                  a.idArea
                                )
                              }
                            >
                              <i class="fas fa-search"></i>
                            </Button>

                      <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaFoto(
                                  a.idArticulo
                                )
                              }
                            >
                              <i class="fas fa-camera"></i>
                            </Button>

                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                {/* <td></td> */}
                <td></td>
                <td className="negrita">TOTAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(total)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalSegundas)}
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
  <Modal size="sm" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>
            Ficha Tecnica
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  // loading="lazy"
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/vixaraArticulos/${idEditArticulo}`}
                  alt=""
                />
              </h6>
            ) : <h4>No se ha subido una Ficha Tecnica....</h4>}
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalDesgloce} toggle={toggleDesgloce}>
        <ModalHeader toggle={toggleDesgloce}>
          <h4>
          Desgloce de Inventario
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={4}>
              <Label>Codigo: {desgloceCodigo}</Label>
            </Col>
            <Col md={4}>
              <Label>Nombre: {desgloceNombre}</Label>
            </Col>
          </Row>

          <Row>
            {/* <Col md={4}>
              <Label>Marca: {desgloceMarca}</Label>
            </Col> */}
            <Col md={4}>
              <Label>Coleccion: {desgloceColeccion}</Label>
            </Col>
          </Row>

          <br />

        <Table size="sm" striped bordered className="table-responsive-xl">
            <tr>
              <th>Color</th>
              <th>Talla</th>
              <th>Cantidad</th>
              <th>Segundas</th>
              <th>Ubicacion</th>
            </tr>
            <tbody>
              {desgloceArticulo
              .sort(
                (a, b) =>
                a.color.localeCompare(b.color) ||
                  a.talla.localeCompare(b.talla),
              )
            .map((a) => {
                return (
                  <tr>
                    <td>{a.color}</td>
                    <td>{a.talla}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.segundas)}</td>
                    <td>{a.ubicacion}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
     
      {loader}
    </>
  );
}

export default ListadoInventarios;
