import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";

import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoPedidosTela() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_EXPLOSION = process.env.REACT_APP_URL_EXPLOSION;
  const URL_EXPLOSION_GLOBAL = process.env.REACT_APP_URL_EXPLOSION_GLOBAL;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 60);

  let valida = new Date();
  valida.setDate(valida.getDate() + 8);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");
  let validador = moment(valida).format("YYYY-MM-DD");

  const [pedidos, setPedidos] = useState([]);

  const [articulos, setArticulos] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedStatusTela, setSelectedStatusTela] = useState("");

  const [selectedArticulo, setSelectedArticulo] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [fecha, setFecha] = useState("");
  const [fecha_cancelacion, setFechaCancelacion] = useState("");
  const [fecha_confirmacion, setFechaConfirmacion] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState("");
  const [total_kilos, setTotalUnidaKilos] = useState("");
  const [total_metros, setTotalUnidaMetros] = useState("");
  const [numero_pedido, setNumeroPedido] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [selectedClienteEdit, setSelectedClienteEdit] = useState("");
  const [selectedColaboradorEdit, setSelectedColaboradorEdit] = useState("");
  const [selectedPatronistaEdit, setSelectedPatronistaEdit] = useState("");
  const [selectedMuestristaEdit, setSelectedMuestristaEdit] = useState("");
  const [selectedPloterEdit, setSelectedPloterEdit] = useState("");
  const [idArticuloEdit, setIdArticuloEdit] = useState("");
  const [observacionesTelaEdit, setObservacionesTelaEdit] = useState("");
  const [total_cantidad_edit, setTotalCantidadEdit] = useState(0);

  const [articulosPedido, setArticulosPedido] = useState([]);
  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_kilos_edit, setTotalKilosEdit] = useState(0);
  const [total_metros_edit, setTotalMetrosEdit] = useState(0);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      resurtido: "",
      unidad: 0,
      colores: "",
      arrayColoresArticulo: [],
      tallas: "",
      arrayTallasArticulo: [],
      cantidad: 0,
      surtido: 0,
      precio: 0,
      descuento: 0,
      total: 0,
      codigoBarras: 0,
    },
  ]);

  const [explosion, setExplosion] = useState([]);
  const [explosionHab, setExplosionHab] = useState([]);
  const [modalExplosion, setModalExplosion] = useState(false);
  const toggleExplosion = () => setModalExplosion(!modalExplosion);


  const [modalExplosionGlobal, setModalExplosionGlobal] = useState(false);
  const toggleExplosionGlobal = () => setModalExplosionGlobal(!modalExplosionGlobal);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("");
  const [selectedActivo, setSelectedActivo] = useState("Si");

  const [statusPedido, setStatusPedido] = useState([]);
  const [statusId, setStatusId] = useState("");
  const [statusNumero, setStatusNumero] = useState("");
  const [statusCodigos, setStatusCodigos] = useState("");
  const [statusFicha, setStatusFicha] = useState("");
  const [statusAuditoria, setStatusAuditoria] = useState("");
  const [statusMuestraFit, setStatusMuestraFit] = useState("");
  const [statusMuestraConfirmacion, setStatusMuestraConfirmacion] =
    useState("");
  const [statusEtiquetas, setStatusEtiquetas] = useState("");

  const [fechaEstCodigos, setFechaEstCodigos] = useState("");
  const [fechaRealCodigos, setFechaRealCodigos] = useState("");
  const [fechaEstFicha, setFechaEstFicha] = useState("");
  const [fechaRealFicha, setFechaRealFicha] = useState("");
  const [fechaEstAuditoria, setFechaEstAuditoria] = useState("");
  const [fechaRealAuditoria, setFechaRealAuditoria] = useState("");
  const [fechaEstMuestraFit, setFechaEstMuestraFit] = useState("");
  const [fechaRealMuestraFit, setFechaRealMuestraFit] = useState("");
  const [fechaEstMuestraConfirmacion, setFechaEstMuestraConfirmacion] =
    useState("");
  const [fechaRealMuestraConfirmacion, setFechaRealMuestraConfirmacion] =
    useState("");
  const [fechaEstEtiquetas, setFechaEstEtiquetas] = useState("");
  const [fechaRealEtiquetas, setFechaRealEtiquetas] = useState("");

  const [modalStatus, setModalStatus] = useState(false);
  const toggleStatus = () => setModalStatus(!modalStatus);

  const [modalAutorizaciones, setModalAutorizaciones] = useState(false);
  const toggleAutorizaciones = () =>
    setModalAutorizaciones(!modalAutorizaciones);
  const [autorizacionId, setAutorizacionId] = useState("");
  const [autorizacionCostos, setAutorizacionCostos] = useState("");
  const [autorizacionDiseno, setAutorizacionDiseno] = useState("");
  const [autorizacionCalidad, setAutorizacionCalidad] = useState("");
  const [autorizacionPloter, setAutorizacionPloter] = useState("");
  const [autorizacionPloterDiseno, setAutorizacionPloterDiseno] = useState("");
  const [autorizacionFit, setAutorizacionFit] = useState("");
  const [autorizacionSurtir, setAutorizacionSurtir] = useState("");

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;

  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
    .get(`${URL_PEDIDOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPedidos = response.data;
        // Array para pagination
        let arrayTabla = allPedidos
          .sort((a, b) => (a.idPedido > b.idPedido ? 1 : -1))
          .map((a) => {
            if (a.status == "Abierto") {
              return {
                _id: a._id,
                numero: a.idPedido,
                activo: a.is_active,
                fecha: a.fecha,
                articulos:
                  "Cod: " +
                  a.articulos[0].codigoCliente +
                  " / Ref: " +
                  a.articulos[0].codigo +
                  " / Desc: " +
                  a.articulos[0].descripcion,
                idArticulo: a.articulos[0]._id,
                patronista:
                  a.articulos[0].patronista[0].nombre +
                  " " +
                  a.articulos[0].patronista[0].apellido,
                idPatronista: a.articulos[0].patronista[0]._id,
                muestrista:
                  a.articulos[0].muestrista[0].nombre +
                  " " +
                  a.articulos[0].muestrista[0].apellido,
                idMuestrista: a.articulos[0].muestrista[0]._id,
                ploter:
                  a.articulos[0].ploter[0].nombre +
                  " " +
                  a.articulos[0].ploter[0].apellido,
                idPloter: a.articulos[0].ploter[0]._id,
                colaboradores:
                  a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
                idColaboradores: a.colaboradores[0]._id,
                fecha_cancelacion: a.fecha_cancelacion,
                fecha_confirmacion: a.fecha_confirmacion,
                observaciones: a.observaciones,
                total_general: a.total_general,
                clientes: a.clientes[0].nombre_comercial,
                idClientes: a.clientes[0]._id,
                total_kilos: a.total_kilos,
                total_metros: a.total_metros,
                status: a.status,
                autorizado: a.autorizado,
                cantidad: parseFloat(a.total_piezas),
                surtido: parseFloat(a.surtido),
                pendiente_surtir: parseFloat(a.pendiente_surtir),
                codigos: a.codigos,
                ficha: a.ficha,
                auditoria: a.auditoria,
                muestraFit: a.muestraFit,
                muestraConfirmacion: a.muestraConfirmacion,
                etiquetas: a.etiquetas,
                fechaEstCodigos: a.fechaEstCodigos,
                fechaRealCodigos: a.fechaRealCodigos,
                fechaEstFicha: a.fechaEstFicha,
                fechaRealFicha: a.fechaRealFicha,
                fechaEstAuditoria: a.fechaEstAuditoria,
                fechaRealAuditoria: a.fechaRealAuditoria,
                fechaEstMuestraFit: a.fechaEstMuestraFit,
                fechaRealMuestraFit: a.fechaRealMuestraFit,
                fechaEstMuestraConfirmacion: a.fechaEstMuestraConfirmacion,
                fechaRealMuestraConfirmacion: a.fechaRealMuestraConfirmacion,
                fechaEstEtiquetas: a.fechaEstEtiquetas,
                fechaRealEtiquetas: a.fechaRealEtiquetas,
                autorizacionCostos: a.autorizacionCostos,
                autorizacionFit: a.autorizacionFit,
                autorizacionDiseno: a.autorizacionDiseno,
                autorizacionCalidad: a.autorizacionCalidad,
                autorizacionPloterDiseno: a.autorizacionPloterDiseno,
                autorizacionPloter: a.autorizacionPloter,
                autorizacionSurtir: a.autorizacionSurtir,
                statusTela: a.statusTela,
                statusHabilitacion: a.statusHabilitacion,
                observacionesTela: a.observacionesTela,
                resurtido: a.resurtido,
                compradores: a.compradores[0].name,
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        let agrupado = data.reduce(function (groups, item) {
          const val = item["_id"];
          groups[val] = groups[val] || {
            _id: item._id,
            surtido: 0,
            pendiente_surtir: 0,
            cantidad: 0,
          };
          groups[val].surtido += item.surtido;
          groups[val].pendiente_surtir += item.pendiente_surtir;
          groups[val].cantidad += item.cantidad;
          groups[val]._id = item._id;
          groups[val].numero = item.numero;
          groups[val].activo = item.activo;
          groups[val].fecha = item.fecha;
          groups[val].articulos = item.articulos;
          groups[val].idArticulo = item.idArticulo;
          groups[val].patronista = item.patronista;
          groups[val].idPatronista = item.idPatronista;
          groups[val].muestrista = item.muestrista;
          groups[val].idMuestrista = item.idMuestrista;
          groups[val].ploter = item.ploter;
          groups[val].idPloter = item.idPloter;
          groups[val].colaboradores = item.colaboradores;
          groups[val].idColaboradores = item.idColaboradores;
          groups[val].fecha_cancelacion = item.fecha_cancelacion;
          groups[val].fecha_confirmacion = item.fecha_confirmacion;
          groups[val].total_general = item.total_general;
          groups[val].clientes = item.clientes;
          groups[val].idClientes = item.idClientes;
          groups[val].status = item.status;
          groups[val].autorizado = item.autorizado;
          groups[val].observaciones = item.observaciones;
          groups[val].codigos = item.codigos;
          groups[val].ficha = item.ficha;
          groups[val].auditoria = item.auditoria;
          groups[val].muestraFit = item.muestraFit;
          groups[val].muestraConfirmacion = item.muestraConfirmacion;
          groups[val].etiquetas = item.etiquetas;
          groups[val].fechaEstCodigos = item.fechaEstCodigos;
          groups[val].fechaRealCodigos = item.fechaRealCodigos;
          groups[val].fechaEstFicha = item.fechaEstFicha;
          groups[val].fechaRealFicha = item.fechaRealFicha;
          groups[val].fechaEstAuditoria = item.fechaEstAuditoria;
          groups[val].fechaRealAuditoria = item.fechaRealAuditoria;
          groups[val].fechaEstMuestraFit = item.fechaEstMuestraFit;
          groups[val].fechaRealMuestraFit = item.fechaRealMuestraFit;
          groups[val].fechaEstMuestraConfirmacion =
            item.fechaEstMuestraConfirmacion;
          groups[val].fechaRealMuestraConfirmacion =
            item.fechaRealMuestraConfirmacion;
          groups[val].fechaEstEtiquetas = item.fechaEstEtiquetas;
          groups[val].fechaRealEtiquetas = item.fechaRealEtiquetas;
          groups[val].autorizacionCostos = item.autorizacionCostos;
          groups[val].autorizacionFit = item.autorizacionFit;
          groups[val].autorizacionDiseno = item.autorizacionDiseno;
          groups[val].autorizacionCalidad = item.autorizacionCalidad;
          groups[val].autorizacionPloter = item.autorizacionPloter;
          groups[val].autorizacionPloterDiseno = item.autorizacionPloterDiseno;
          groups[val].autorizacionSurtir = item.autorizacionSurtir;
          groups[val].statusTela = item.statusTela;
          groups[val].statusHabilitacion = item.statusHabilitacion;
          groups[val].observacionesTela = item.observacionesTela;
          groups[val].resurtido = item.resurtido;
          groups[val].compradores = item.compradores;

          return groups;
        }, []);

        let dataFinal = Object.values(agrupado);
        setPedidos(dataFinal);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedActivo, selectedFechaInicio, selectedFechaFin]);

  const ListaPedidos = comments.sort((a, b) =>
    a.idPedido > b.idPedido ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaPedidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.colaboradores,
          a.articulo,
          a.fecha_cancelacion,
          a.fecha_confirmacion,
          a.clientes,
          a.total_kilos,
          a.total_metros,
          "$" + new Intl.NumberFormat("en-US").format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Pedidos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Articulo",
          "Fecha Cancelacion",
          "Fecha Confirmacion",
          "Cliente",
          "Kilos",
          "Metros",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    // doc.save(`Pedidos.pdf`);

    window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    const dataExcel = ListaPedidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores)
      ) {
        return {
          Activo: a.activo,
          Numero: a.numero,
          Fecha: a.fecha,
          Vendedor: a.colaboradores,
          Articulo: a.articulos,
          Cancelacion: a.fecha_cancelacion,
          Confirmacion: a.fecha_confirmacion,
          Cliente: a.clientes,
          Kilos: a.total_kilos,
          Metros: a.total_metros,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoPedidos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoPedidos",
        sheetFilter: [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Articulo",
          "Cancelacion",
          "Confirmacion",
          "Cliente",
          "Kilos",
          "Metros",
          "Total",
        ],
        sheetHeader: [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Articulo",
          "Cancelacion",
          "Confirmacion",
          "Cliente",
          "Kilos",
          "Metros",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaPedidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.colaboradores,
          a.articulos,
          a.fecha_cancelacion,
          a.fecha_confirmacion,
          a.clientes,
          a.total_kilos,
          a.total_metros,
          "$" + new Intl.NumberFormat("en-US").format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Ordenes de Compra`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Articulo",
          "Fecha Cancelacion",
          "Fecha Confirmacion",
          "Cliente",
          "Kilos",
          "Metros",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Pedidos",
          email: mailTo,
          fileName: "ListadoPedidos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Pedidos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    idEdit,
    numero_pedido,
    observacionesTela
  ) {
    setIdEdit(idEdit);
    setNumeroPedido(numero_pedido);
    setObservacionesTelaEdit(observacionesTela);
    toggleEdit();
  }

  async function PDFOC(
    fecha,
    colaboradores,
    compradores,
    fecha_cancelacion,
    fecha_confirmacion,
    idClientes,
    clientes,
    total_kilos,
    total_metros,
    total_general,
    observaciones,
    numero,
    idPDFOC
  ) {
    await axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            if (a.cantidad > 0) {
              return {
                id: a._id,
                articulos:
                  a.articulos[0].codigoCliente + " " + a.articulos[0].codigo,
                unidad: a.articulos[0].unidad,
                colores: a.colores[0].name,
                tallas: a.tallas[0].name,
                cantidad: a.cantidad,
                surtido: a.surtido,
                precio: a.precio,
                precioEtiqueta: a.precioEtiqueta,
                descuento: a.descuento,
                total: a.total,
                pendiente_surtir: a.pendiente_surtir,
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        // let mts = dataFinal.filter((e) => e.unidad == "Metros");
        let totalMts = dataFinal.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalCantidadEdit(TM);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);

        const data = dataFinal.map((a) => {
          return [
            a.articulos,
            a.colores,
            a.tallas,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US").format(a.surtido),
            "$" + new Intl.NumberFormat("en-US").format(a.precio),
            a.descuento + " %",
            "$" + new Intl.NumberFormat("en-US").format(a.total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });

        axios
          .get(`${URL_EXPLOSION_GLOBAL}Pedido/${idPDFOC}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let allExplosion = response.data;
            let arrayTablaEx = allExplosion
              .map((a) => {
                if (a.cantidad > 0) {
                  return {
                    id: a._id,
                    tela: a.telas[0].nombre,
                    color: a.colores[0].name,
                    cantidad: a.cantidad,
                    unidad: a.telas[0].unidad,
                    cantidadPrenda: a.cantidadPrenda,
                    observaciones: a.observaciones,
                  };
                }
              })
              .filter(function (el) {
                return el != null;
              });

            let dataFinalEx = Object.values(arrayTablaEx);

            const dataEx = dataFinalEx.map((a) => {
              return [
                a.tela,
                a.color,
                new Intl.NumberFormat("en-US").format(a.cantidad) +
                  " " +
                  a.unidad,
                new Intl.NumberFormat("en-US").format(a.cantidadPrenda),
                a.observaciones,
              ];
            });
            const dataPDFEX = Object.values(dataEx);
            const dataPDFLimpiaEX = dataPDFEX.filter(function (el) {
              return el != null;
            });

            const doc = new jsPDF("landscape");
            var img = new Image();
            img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
            doc.addImage(img, "png", 230, 30, 45, 15);
            var img2 = new Image();
            img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
            doc.addImage(img2, "png", 120, 15, 60, 25);
            doc.text(`Pedido # ${numero}`, 20, 20);
            doc.setFontSize(12);
            doc.text(`Fecha ${fecha}`, 20, 25);
            doc.text(`Cliente ${clientes}`, 20, 30);
            doc.text(`Vendedor ${colaboradores}`, 20, 35);
            doc.text(`Comprador ${compradores}`, 20, 40);
            doc.text(
              `Cancelacion ${fecha_cancelacion}, Confirmacion ${fecha_confirmacion}`,
              20,
              45
            );
            doc.setFont(undefined, "bold");
            doc.text(`Observaciones ${observaciones}`, 20, 50);
            doc.autoTable({
              head: [
                [
                  "Articulo",
                  "Color",
                  "Talla",
                  "Cantidad",
                  "Surtido",
                  "Precio",
                  "Descuento",
                  "Total",
                ],
              ],
              body: dataPDFLimpia,
              startY: 55,
              foot: [
                [
                  "",
                  "",
                  "",
                  new Intl.NumberFormat("en-US").format(TM),
                  "",
                  "",
                  "Total",
                  "$" + new Intl.NumberFormat("en-US").format(total_general),
                ],
              ],
              showFoot: "lastPage",
            });



            doc.autoTable({
              head: [
                [
                  "Tela",
                  "Color",
                  "Cantidad",
                  "Consumo Prenda",
                  "Observaciones",
                ],
              ],
              body: dataPDFLimpiaEX,
              startY: doc.autoTable.previous.finalY + 13,
              // foot: [
              //   [
              //     "",
              //     "",
              //     "",
              //     new Intl.NumberFormat("en-US").format(TM),
              //     "",
              //     "Total",
              //     "$" + new Intl.NumberFormat("en-US").format(total_general),
              //   ],
              // ],
              // showFoot: "lastPage",
            });
            // doc.save(`Pedido-${numero}.pdf`);
            window.open(doc.output("bloburl"), "_blank");
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function editPedidos(event) {
    event.preventDefault();
    const URL_PEDIDOS_EDIT = `${process.env.REACT_APP_URL_PEDIDOS}/${idEdit}`;
      axios
        .patch(
          URL_PEDIDOS_EDIT,
          {
            observacionesTela: observacionesTelaEdit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )

            .then(() => {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Editar Pedido",
                  detalle: `${numero_pedido} ${fecha}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );

              toggleEdit();
              Swal.fire("Good job!", "Actualizado con exito", "success");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
  }

  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Resurtido", field: "resurtido", sortable: true },
    { name: "Fecha Cita Venta", field: "fecha", sortable: false },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: false },
    { name: "Articulo", field: "articulo", sortable: false },
    { name: "Fecha Creacion", field: "fecha", sortable: true },
    { name: "Fecha Cancelacion", field: "fecha_cancelacion", sortable: true },
    { name: "Fecha Confirmacion", field: "fecha_confirmacion", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
    { name: "Surtido", field: "surtido", sortable: true },
    { name: "Pend Surtir", field: "pendiente_surtir", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
    { name: "Obs. Tela", field: "observacionesTela", sortable: true },
    { name: "Info", field: "info", sortable: false },
    // { name: "Autorizaciones", field: "autorizaciones", sortable: false },
    // { name: "Editar", field: "editar", sortable: false },
    { name: "Status", field: "status", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          // comment.activo.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.articulos.toLowerCase().includes(search.toLowerCase()) ||
          comment.fecha.toLowerCase().includes(search.toLowerCase()) ||
          comment.observacionesTela.toLowerCase().includes(search.toLowerCase()) ||
          comment.fecha_cancelacion
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.fecha_confirmacion
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.surtido.toString().includes(search) ||
          comment.pendiente_surtir.toString().includes(search) ||
          comment.total_general.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }

    if (selectedStatusTela) {
      computedComments = computedComments.filter((e) =>
        e.statusTela.includes(selectedStatusTela)
      );
    }

    if (selectedArticulo) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedArticulo)
      );
    }
    // if (activo) {
    //   computedComments = computedComments.filter((e) =>
    //     e.activo.includes(activo)
    //   );
    // }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" &&
      sorting.field != "surtido" &&
      sorting.field != "pendiente_surtir" &&
      sorting.field != "total_general" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "surtido" ||
        sorting.field == "pendiente_surtir" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "surtido" ||
        sorting.field == "pendiente_surtir" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedColaborador,
    selectedStatusTela,
    selectedArticulo,
    // activo,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  function EditArticulos(idOC, numero_pedido) {
    setInputFields([
      {
        id: uuidv4(),
        articulos: "",
        resurtido: "",
        unidad: 0,
        colores: "",
        arrayColoresArticulo: [],
        tallas: "",
        arrayTallasArticulo: [],
        cantidad: 0,
        surtido: 0,
        precio: 0,
        descuento: 0,
        total: 0,
        codigoBarras: 0,
      },
    ]);
    setIdEdit(idOC);
    setNumeroPedido(numero_pedido);
    axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${idOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              activo: a.is_active,
              articulos: a.articulos[0]._id,
              unidad: a.articulos[0].unidad,
              colores: a.colores[0]._id,
              tallas: a.tallas[0]._id,
              arrayColoresArticulo: a.articulos[0].colores,
              arrayTallasArticulo: a.articulos[0].tallas,
              cantidad: a.cantidad,
              surtido: a.surtido,
              precio: a.precio,
              descuento: a.descuento,
              total: a.total,
              pendiente_surtir: a.pendiente_surtir,
              resurtido: a.resurtido,
              codigoBarras: a.codigoBarras,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setArticulosPedido(dataFinal);

        let mts = dataFinal.filter((e) => e.unidad == "Metros");
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetrosEdit(TM);
        let KG = dataFinal.filter((e) => e.unidad == "Kilos");
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilosEdit(TK);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);
      })
      .catch((err) => {
        console.log(err);
      });
    toggleEditArticulos();
  }

  const handleChangeInputExistentes = (id, event) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
        i.pendiente_surtir = i.cantidad - i.surtido;
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  function BuscaArticuloExistentes(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        let unidadArticulo = a.unidad;
        let coloresArticulo = a.colores;
        let tallasArticulo = a.tallas;
        handleChangeInputExistentesArticulo(
          id,
          idArticulo,
          unidadArticulo,
          coloresArticulo,
          tallasArticulo
        );
      }
    });
  }

  const handleChangeInputExistentesArticulo = (
    id,
    idArticulo,
    unidadArticulo,
    coloresArticulo,
    tallasArticulo
  ) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.unidad = unidadArticulo;
        i.arrayColoresArticulo = coloresArticulo;
        i.arrayTallasArticulo = tallasArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
        i.pendiente_surtir = i.cantidad - i.surtido;
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        resurtido: ultimo.resurtido,
        articulos: ultimo.articulos,
        unidad: ultimo.unidad,
        colores: "",
        arrayColoresArticulo: ultimo.arrayColoresArticulo,
        tallas: "",
        arrayTallasArticulo: ultimo.arrayTallasArticulo,
        cantidad: 0,
        precio: ultimo.precio,
        descuento: ultimo.descuento,
        total: 0,
        codigoBarras: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mtsExistentes = articulosPedido.filter((e) => e.unidad == "Metros");
    let mtsNuevos = values.filter((e) => e.unidad == "Metros");
    let mts = mtsExistentes.concat(mtsNuevos);
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetrosEdit(TM);

    let KGExistentes = articulosPedido.filter((e) => e.unidad == "Kilos");
    let KGNuevos = values.filter((e) => e.unidad == "Kilos");
    let KG = KGExistentes.concat(KGNuevos);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilosEdit(TK);

    let importesExistentes = articulosPedido.map((c) => parseFloat(c.total));
    let importesNuevos = values.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        let unidadArticulo = a.unidad;
        let coloresArticulo = a.colores;
        let tallasArticulo = a.tallas;
        handleChangeInputArticulo(
          id,
          idArticulo,
          unidadArticulo,
          coloresArticulo,
          tallasArticulo
        );
      }
    });
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    unidadArticulo,
    coloresArticulo,
    tallasArticulo
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.unidad = unidadArticulo;
        i.arrayColoresArticulo = coloresArticulo;
        i.arrayTallasArticulo = tallasArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let mtsExistentes = articulosPedido.filter((e) => e.unidad == "Metros");
    let mtsNuevos = inputFields.filter((e) => e.unidad == "Metros");
    let mts = mtsExistentes.concat(mtsNuevos);
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetrosEdit(TM);

    let KGExistentes = articulosPedido.filter((e) => e.unidad == "Kilos");
    let KGNuevos = inputFields.filter((e) => e.unidad == "Kilos");
    let KG = KGExistentes.concat(KGNuevos);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilosEdit(TK);

    let importesExistentes = articulosPedido.map((c) => parseFloat(c.total));
    let importesNuevos = inputFields.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  }

  const savePedido = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalPedido = inputFields.length + articulosPedido.length;
    const URL_PEDIDOS_EDIT = `${process.env.REACT_APP_URL_PEDIDOS}/${idEdit}`;
    try {
      await axios
        .patch(
          URL_PEDIDOS_EDIT,
          {
            total_general: total_general_edit,
            // total_kilos: total_kilos_edit,
            // total_metros: total_metros_edit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          inputFields.map((a) => {
            if (a.articulos != "" && a.colores != "" && a.cantidad != 0) {
              axios
                .post(
                  URL_ARTICULOS_PEDIDO,
                  {
                    pedidos: idEdit,
                    articulos: a.articulos,
                    colores: a.colores,
                    tallas: a.tallas,
                    cantidad: parseFloat(a.cantidad),
                    precio: parseFloat(a.precio),
                    descuento: parseFloat(a.descuento),
                    total: parseFloat(a.total),
                    surtido: 0,
                    pendiente_surtir: parseFloat(a.cantidad),
                    codigoBarras: a.codigoBarras,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalPedido = totalPedido - 1;
                  if (totalPedido == 0) {
                    axios.post(
                      URL_LOGS,
                      {
                        tipo: "Editar Pedido",
                        detalle: `${numero_pedido}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                });
            } else {
              totalPedido = totalPedido - 1;
              if (totalPedido == 0) {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Editar Pedido",
                    detalle: `${numero_pedido}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            }
          });
          articulosPedido.map((a) => {
            axios
              .patch(
                `${URL_ARTICULOS_PEDIDO}/${a.id}`,
                {
                  articulos: a.articulos,
                  colores: a.colores,
                  tallas: a.tallas,
                  cantidad: parseFloat(a.cantidad),
                  precio: parseFloat(a.precio),
                  descuento: parseFloat(a.descuento),
                  total: parseFloat(a.total),
                  pendiente_surtir: parseFloat(a.pendiente_surtir),
                  codigoBarras: a.codigoBarras,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalPedido = totalPedido - 1;
                if (totalPedido == 0) {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Editar Pedido",
                      detalle: `${numero_pedido}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  async function requisicionGlobal(id, numero) {
    setExplosion([]);
    setIdEdit(id);
    setNumeroPedido(numero);

          axios
            .get(`${URL_EXPLOSION_GLOBAL}Pedido/${id}`, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((response) => {
              let allExplosion = response.data;
              let arrayExp = allExplosion
                .map((a) => {
                    return{
                      tela: a.telas[0].nombre + " / " + a.telas[0].tiposTelas[0].name,
                      color: a.colores[0].name,
                      cantidad: a.cantidad,
                      cantidadPrenda: a.cantidadPrenda,
                      observaciones: a.observaciones
                    };
                })
                .filter(function (el) {
                  return el != null;
                });
                setExplosion(arrayExp);
            })
            .catch((err) => {
              console.log(err);
            });
      
            toggleExplosionGlobal();
        
      
  }

  async function requisicion(id, numero) {
    setExplosion([]);
    setExplosionHab([]);
    toggleProgreso();
    setIdEdit(id);
    setNumeroPedido(numero);
    axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(async (response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = await allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              activo: a.is_active,
              articulos: a.articulos[0]._id,
              articulosCodigo: a.articulos[0].codigo,
              colores: a.colores[0]._id,
              coloresName: a.colores[0].name,
              tallas: a.tallas[0]._id,
              tallasName: a.tallas[0].name,
              cantidad: a.cantidad,
              surtido: a.surtido,
              pendiente_surtir: a.pendiente_surtir,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        let agrupado = data.reduce(function (groups, item) {
          const val = item["articulos"];
          groups[val] = groups[val] || {
            articulos: item.articulos,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].articulos = item.articulos;
          groups[val].articulosCodigo = item.articulosCodigo;
          return groups;
        }, []);

        let dataFinal = Object.values(agrupado);

        let agrupadoColores = data.reduce(function (groups, item) {
          const val = item["articulos"] + item["colores"];
          groups[val] = groups[val] || {
            articulos: item.articulos,
            colores: item.colores,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].articulos = item.articulos;
          groups[val].articulosCodigo = item.articulosCodigo;
          groups[val].colores = item.colores;
          groups[val].coloresName = item.coloresName;
          return groups;
        }, []);

        let dataFinalColores = Object.values(agrupadoColores);

        let temp2 = [];
        let temp3 = [];

        let exp = await dataFinal.map((a) => {
          axios
            .get(`${URL_EXPLOSION}Articulo/${a.articulos}`, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((response) => {
              let allExplosion = response.data;
              let arrayExp = allExplosion
                .map((b) => {
                  if (b.habilitacion != "") {
                    temp2.push({
                      articulos: a.articulos,
                      articulosCodigo: a.articulosCodigo,
                      material: b.habilitacion[0].nombre,
                      cantidad: b.cantidad * a.cantidad,
                      colores: "NA",
                      coloresName: "NA",
                      tipo: "Habilitacion",
                      observaciones: b.observaciones,
                    });
                  }
                })
                .filter(function (el) {
                  return el != null;
                });
            })
            .catch((err) => {
              console.log(err);
            });
        });

        let expColores = await dataFinalColores.map((a) => {
          axios
            .get(`${URL_EXPLOSION}Articulo/${a.articulos}`, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((response) => {
              let allExplosion = response.data;
              let arrayExp = allExplosion
                .map((b) => {
                  if (b.telas != "") {
                    temp3.push({
                      articulos: a.articulos,
                      articulosCodigo: a.articulosCodigo,
                      material:
                        b.telas[0].nombre +
                        " / " +
                        b.telas[0].tiposTelas[0].name,
                      cantidad: b.cantidad * a.cantidad,
                      colores: a.colores,
                      coloresName: a.coloresName,
                      tipo: "Tela",
                      observaciones: b.observaciones,
                    });
                  }
                })
                .filter(function (el) {
                  return el != null;
                });
            })
            .catch((err) => {
              console.log(err);
            });
        });
        setExplosion(temp3);
        setExplosionHab(temp2);
        setTimeout(() => {
          setModalProgreso(false);
          toggleExplosion();
        }, 3500);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  function jalaFoto(idEdit) {
    setPhoto("");
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idEdit}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        if (data.data.version) {
          setPhoto(data.data.version);
        }
      })
      .catch((err) => console.log(err));

    setIdEdit(idEdit);
    toggleFoto();
  }



  function statusTelaPedido(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se marcara como Pedida!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, marcar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_PEDIDOS}/${id}`,
            {
              statusTela: "Pedido",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            const newComments = comments.map((i) => {
              if (id === i._id) {
                i.statusTela = "Pedido";
              }
              return i;
            });
            setComments(newComments);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se marco como pedido",
              showConfirmButton: false,
              timer: 2000,
            });
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function statusTelaRecibido(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se marcara como Recibida!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, marcar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_PEDIDOS}/${id}`,
            {
              statusTela: "Recibido",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            const newComments = comments.map((i) => {
              if (id === i._id) {
                i.statusTela = "Recibido";
              }
              return i;
            });
            setComments(newComments);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se marco como recibido",
              showConfirmButton: false,
              timer: 2000,
            });
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_pedidos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {/* {user.pedidos_create ? (
                <>
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ArticulosCreate"
                >
                  Nuevo Prepedido
                </Button>

                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/PedidosCreate"
                >
                  Pedido Resurtido
                </Button>
                </>
              ) : (
                <>
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Prepedido
                </Button>

                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Pedido Resurtido
                </Button>
                </>
              )} */}
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                {/* <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={desarrollo}
                >
                  Desarrollo
                </Button> */}
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Pedidos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Pedidos Tela</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Clientes</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label>Vendedor</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedColaborador}
                onChange={(e) => {
                  setSelectedColaborador(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label>Articulo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedArticulo}
                onChange={(e) => {
                  setSelectedArticulo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {articulos
                  .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.codigo} {a.codigoCliente} {a.descripcion}
                      </option>
                    );
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label>Status</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedStatusTela}
                required
                onChange={(e) => {
                  setSelectedStatusTela(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Pendiente">Pendiente</option>
                <option value="Pedido">Pedido</option>
                <option value="Recibido">Recibido</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                return (
                  // <tr style={{ backgroundColor: "#ed8c8c" }}>
                  <tr>
                    <td>{a.numero}</td>
                    <td>{a.resurtido}</td>
                    <td>{a.fecha}</td>
                    <td>{a.clientes}</td>
                    <td>{a.colaboradores}</td>
                    <td>{a.articulos}</td>
                    <td>{a.fecha}</td>
                    <td>{a.fecha_cancelacion}</td>
                    <td>{a.fecha_confirmacion}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.surtido)}</td>
                    <td>
                      {new Intl.NumberFormat("en-US").format(
                        a.pendiente_surtir
                      )}
                    </td>
                    <td>
                      {"$" +
                        new Intl.NumberFormat("en-US").format(
                          a.total_general
                        )}{" "}
                    </td>
                    <td>{a.observacionesTela}</td>
                    <td>
                      {user.pedidos_create ? (
                        <div>
                          <Button
                            color="danger"
                            id="Editar"
                            size="sm"
                            onClick={(e) => requisicionGlobal(a._id, a.numero)}
                          >
                            {/* <i class="fas fa-tag"></i> */}
                            <i class="fas fa-tape"></i>
                          </Button>
                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) => requisicion(a._id, a.numero)}
                          >
                            <i class="fas fa-tag"></i>
                          </Button>

                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={(e) =>
                              PDFOC(
                                a.fecha,
                                a.colaboradores,
                                a.compradores,
                                a.fecha_cancelacion,
                                a.fecha_confirmacion,
                                a.idClientes,
                                a.clientes,
                                a.total_kilos,
                                a.total_metros,
                                a.total_general,
                                a.observaciones,
                                a.numero,
                                a._id
                              )
                            }
                          >
                            <i class="far fa-file-pdf"></i>
                          </Button>
                        </div>
                      ) : undefined}
                    </td>
                    <td>
                      <>

                      <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) =>
                              jalaInfo(
                                a._id,
                                a.numero,
                                a.observacionesTela
                              )
                            }
                          >
                            <i class="far fa-edit"></i>
                          </Button>
                          
                        {a.statusTela == "Pendiente" ? (
                          <Button
                            color="danger"
                            onClick={(e) => statusTelaPedido(a._id)}
                            size="sm"
                          >
                            <i class="fas fa-tape"></i>
                          </Button>
                        ) : a.statusTela == "Pedido" ? (
                          <Button
                            color="info"
                            onClick={(e) => statusTelaRecibido(a._id)}
                            size="sm"
                          >
                            <i class="fas fa-tape"></i>
                          </Button>
                        ) : (
                          <Button color="primary" size="sm" disabled>
                            <i class="fas fa-tape"></i>
                          </Button>
                        )}
                      </>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Pedido {numero_pedido}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observacionesTelaEdit}
                required
                onChange={(e) => {
                  setObservacionesTelaEdit(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editPedidos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalEditArticulos}
        toggle={toggleEditArticulos}
      >
        <ModalHeader toggle={toggleEditArticulos}>
          <h4>Editar Articulos Pedido {numero_pedido}</h4>
          <h4>
            TOTALES {new Intl.NumberFormat("en-US").format(total_general_edit)}{" "}
            pzas. /{" "}
            {"$" + new Intl.NumberFormat("en-US").format(total_general_edit)}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={savePedido}>
            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Resurtido</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Colores</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Tallas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Codigo Barras</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Surtido</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Descuento</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Activo</Label>
              </Col>
            </Row>

            {articulosPedido.map((ao) => (
              <div key={ao.id}>
                <Row>
                  {ao.surtido == 0 ? (
                    <>
                      <Col md={2}>
                        <Input
                          name="articulos"
                          type="select"
                          value={ao.articulos}
                          required
                          onChange={(event) => {
                            BuscaArticuloExistentes(ao.id, event);
                          }}
                        >
                          <option value="">Selecciona</option>
                          {articulos
                            .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                            .map((a) => {
                              return (
                                <option value={a._id}>
                                  {a.codigo} / {a.nombre}
                                </option>
                              );
                            })}
                        </Input>
                      </Col>
                      <Col md={1}>
                        <Input
                          name="resurtido"
                          type="select"
                          value={ao.resurtido}
                          required
                          onChange={(event) => {
                            handleChangeInputExistentes(ao.id, event);
                          }}
                        >
                          <option value="">Selecciona</option>
                          <option value="Si">Si</option>
                          <option value="No">No</option>
                        </Input>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col md={2}>
                        <Input
                          name="articulos"
                          type="select"
                          value={ao.articulos}
                          required
                          disabled
                        >
                          <option value="">Selecciona</option>
                          {articulos
                            .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                            .map((a) => {
                              return (
                                <option value={a._id}>
                                  {a.codigo} / {a.nombre}
                                </option>
                              );
                            })}
                        </Input>
                      </Col>
                      <Col md={1}>
                        <Input
                          name="resurtido"
                          type="select"
                          value={ao.resurtido}
                          required
                          disabled
                        >
                          <option value="">Selecciona</option>
                          <option value="Si">Si</option>
                          <option value="No">No</option>
                        </Input>
                      </Col>
                    </>
                  )}

                  {ao.surtido == 0 ? (
                    <>
                      <Col md={1}>
                        <Input
                          name="colores"
                          type="select"
                          value={ao.colores}
                          required
                          onChange={(event) =>
                            handleChangeInputExistentes(ao.id, event)
                          }
                        >
                          <option value="">Selecciona</option>
                          {ao.arrayColoresArticulo
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                            })}
                        </Input>
                      </Col>
                      <Col md={1}>
                        <Input
                          name="tallas"
                          type="select"
                          value={ao.tallas}
                          required
                          onChange={(event) =>
                            handleChangeInputExistentes(ao.id, event)
                          }
                        >
                          <option value="">Selecciona</option>
                          {ao.arrayTallasArticulo
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                            })}
                        </Input>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col md={1}>
                        <Input
                          name="colores"
                          type="select"
                          value={ao.colores}
                          required
                          disabled
                        >
                          <option value="">Selecciona</option>
                          {ao.arrayColoresArticulo
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                            })}
                        </Input>
                      </Col>
                      <Col md={1}>
                        <Input
                          name="tallas"
                          type="select"
                          value={ao.tallas}
                          required
                          disabled
                        >
                          <option value="">Selecciona</option>
                          {ao.arrayTallasArticulo
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                            })}
                        </Input>
                      </Col>
                    </>
                  )}

                  <Col md={1}>
                    <Input
                      name="codigoBarras"
                      type="number"
                      placeholder="CodigoBarras"
                      value={ao.codigoBarras}
                      onChange={(event) =>
                        handleChangeInputExistentes(ao.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      name="cantidad"
                      type="number"
                      placeholder="Cantidad"
                      value={ao.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInputExistentes(ao.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      name="surtido"
                      type="number"
                      placeholder="surtido"
                      value={ao.surtido.toFixed(2)}
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      name="precio"
                      type="number"
                      placeholder="Precio"
                      value={ao.precio}
                      required
                      onChange={(event) =>
                        handleChangeInputExistentes(ao.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="descuento"
                      type="number"
                      placeholder="Descuento"
                      value={ao.descuento}
                      required
                      onChange={(event) =>
                        handleChangeInputExistentes(ao.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="total"
                      type="number"
                      placeholder="Total"
                      value={ao.total}
                      disabled
                      onChange={(event) =>
                        handleChangeInputExistentes(ao.id, event)
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <Baja
                      idStatus={ao.id}
                      is_active={ao.activo}
                      URL_BAJA={process.env.REACT_APP_URL_ARTICULOS_PEDIDO}
                    />
                  </Col>
                </Row>
              </div>
            ))}

            {/* Agregar mas articulos */}

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Input
                      // bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.articulos}
                      required
                      onChange={(event) => {
                        BuscaArticulo(inputField.id, event);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {articulos
                        .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.codigo} / {a.nombre}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      name="resurtido"
                      type="select"
                      value={inputField.resurtido}
                      onChange={(event) => {
                        handleChangeInput(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona</option>
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="colores"
                      type="select"
                      value={inputField.colores}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    >
                      <option value="0">Selecciona</option>
                      {inputField.arrayColoresArticulo
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="tallas"
                      type="select"
                      value={inputField.tallas}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    >
                      <option value="0">Selecciona</option>
                      {inputField.arrayTallasArticulo
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      name="codigoBarras"
                      type="number"
                      placeholder="CodigoBarras"
                      value={inputField.codigoBarras}
                      required
                      onChange={(event) =>
                        handleChangeInputExistentes(inputField.id, event)
                      }
                    />
                  </Col>

                  {inputField.colores == "" ? (
                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        disabled
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                  ) : (
                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                  )}

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="surtido"
                      type="number"
                      placeholder="0"
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="precio"
                      type="number"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="descuento"
                      type="number"
                      placeholder="Descuento"
                      value={inputField.descuento}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="total"
                      type="number"
                      placeholder="Total"
                      value={inputField.total}
                      disabled
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={6}>
                <h4 id="logoutBoton">
                  TOTALES{" "}
                  {new Intl.NumberFormat("en-US").format(total_general_edit)}{" "}
                  pzas. /{" "}
                  {"$" +
                    new Intl.NumberFormat("en-US").format(total_general_edit)}
                </h4>
              </Col>
            </Row>
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios minutos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal size="xl" isOpen={modalExplosion} toggle={toggleExplosion}>
        <ModalHeader toggle={toggleExplosion}>
          <h4>Explosion</h4>
        </ModalHeader>
        <ModalBody>
          <h5>Tela</h5>
          <Table size="xl" striped borderless className="table-responsive-xl">
            <tr>
              <th>Articulo</th>
              <th>Material</th>
              <th>Color</th>
              <th>Cantidad</th>
              <th>Observaciones</th>
            </tr>
            {explosion.map((a) => (
              <tr>
                <td>{a.articulosCodigo}</td>
                <td>{a.material}</td>
                <td>{a.coloresName}</td>
                <td>{a.cantidad}</td>
                <td>{a.observaciones}</td>
              </tr>
            ))}
          </Table>
          <br />

          {/* <h5>Habilitacion</h5>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <tr>
              <th>Articulo</th>
              <th>Material</th>
              <th>Cantidad</th>
              <th>Observaciones</th>
            </tr>
            {explosionHab.map((a) => (
              
              <tr>
                <td>{a.articulosCodigo}</td>
                <td>{a.material}</td>
                <td>{a.cantidad}</td>
                <td>{a.observaciones}</td>
              </tr>
            )
            
            )}
          </Table> */}
        </ModalBody>
      </Modal>


      <Modal size="xl" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>Pedido Cliente</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  // loading="lazy"
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/vixaraArticulos/${idEdit}`}
                  alt=""
                />
              </h6>
            ) : (
              <h4>No se ha subido el Pedido del Cliente....</h4>
            )}
          </Row>
        </ModalBody>
      </Modal>
      <Modal size="xl" isOpen={modalExplosionGlobal} toggle={toggleExplosionGlobal}>
        <ModalHeader toggle={toggleExplosionGlobal}>
          <h4>Explosion Global Tela</h4>
        </ModalHeader>
        <ModalBody>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <tr>
              <th>Tela</th>
              <th>Color</th>
              <th>Metros Totales</th>
              <th>Consumo Por Prenda</th>
              <th>Observaciones</th>
            </tr>
            {explosion.map((a) => (
              <tr>
                <td>{a.tela}</td>
                <td>{a.color}</td>
                <td>{a.cantidad}</td>
                <td>{a.cantidadPrenda}</td>
                <td>{a.observaciones}</td>
              </tr>
            ))}
          </Table>
          <br />

        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoPedidosTela;
